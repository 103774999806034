import React from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./BestSeller.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// function buttonCLicked() {
//   // const history = useHistory();
//   // history.push({
//   //   pathname: "/products/60ed5aabbd5fe23f44a94021",
//   // });
// }

function Review() {
  return (
    <React.Fragment>
      <div className="bestClass">
        <h3
          style={{
            fontWeight: "lighter",
            fontFamily: "Josefin Sans,sans-serif",
          }}
        >
          Customer Testimonial
        </h3>
        <center>
          {" "}
          <h4></h4>
        </center>
        <Carousel className="imgClass" responsive={responsive}>
          <h5
            style={{
              paddingTop: "20px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              fontWeight: "lighter",
              fontFamily: "Josefin Sans,sans-serif",
            }}
          >
            --“coffee has a rich and dense flavor, it is aromatic. #Trust worthy
            company #Believing that you can pay in advance.”
            <b>—Shyamasree Roy(West Bengal)</b>
          </h5>
          <h5
            style={{
              paddingTop: "20px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              fontWeight: "lighter",
              fontFamily: "Josefin Sans,sans-serif",
            }}
          >
            --“Imagine having from beans to prossesing everything done to
            perfection. I've known the brand for few months now, they leave no
            stone unturned when it comes to quality service and consistent
            taste. These are the people with passion for coffee.”
            <b>—Harish(TamilNadu)</b>”
          </h5>
          <h5
            style={{
              paddingTop: "20px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              fontWeight: "lighter",
              fontFamily: "Josefin Sans,sans-serif",
            }}
          >
            --“The Coffee is very good. The aroma and flavor is perfect for a
            black coffee. Ordered french press grind and was surprised with the
            flavor.”
            <b>—Rahul Singh(Punjab)</b>”
          </h5>
          <h5
            style={{
              paddingTop: "20px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              fontWeight: "lighter",
              fontFamily: "Josefin Sans,sans-serif",
            }}
          >
            --“Great coffee, was looking for a new taste. Tastes best as
            everything is done in house. I recommend this to anyone looking for
            a great tasting coffee”<b>—  vaishak(Karnataka)</b>
          </h5>
        </Carousel>
      </div>
      <div style={{marginBottom:'60px'}}>
        <center>
          <iframe
            width="80%"
            height="515"
            src="https://www.youtube.com/embed/ferEAqJNwn8?si=N68tuOF0RUlmv-Md"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </center>
      </div>
    </React.Fragment>
  );
}

export default Review;
