import validCouponCode from '../order/couponCodes'
export const subTotal = (id, price) => {
  let subTotalCost = 0;
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.filter((item) => {
    if (item.id === id) {
      subTotalCost = item.quantitiy * price;
    }
  });
  return subTotalCost;
};

export const quantity = (id) => {
  let product = 0;
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.filter((item) => {
    if (item.id === id) {
      product = item.quantitiy;
    }
  });
  return product;
};

export const totalCost = ({couponCode, isCouponCodeValid}={}) => {
  let totalCost = 0;
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.map((item) => {
    totalCost += item.quantitiy * item.price;
  });
	// console.log(totalCost)
	if(isCouponCodeValid && couponCode && validCouponCode.find(v=>v.code===couponCode)){
		let dis = validCouponCode.find(v=>v.code===couponCode)
		if(dis.discountPerc) totalCost = totalCost - ((totalCost * dis.discountPerc)/100)
		else if (dis.discountCash) totalCost = totalCost - dis.discountCash
	}
  return totalCost;
};

export const shippingPrice = (state) => {
  if (state === "Karnataka") {
    return 40;
  } else {
    return 50;
  }
};
