import React, { useContext, useState, useEffect } from "react";
import { HomeContext } from "./index";
import { getAllCategory } from "../../admin/categories/FetchApi";
import { getAllProduct } from "../../admin/products/FetchApi";
import "./style.css";
import { Link } from "react-router-dom";

export const Search = () => {
  const { homeContextData, dispatch } = useContext(HomeContext);
  const [search, setSearch] = useState("");
  const [productArray, setPa] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const SearchResults = () => {
    if (productArray != null && productArray.length > 0 && search != "") {
      const ListItem = ({ x }) => {
        return (
          <Link to={"/products/" + x._id}>
            <li className="my-1 px-2 border-solid border-2 border-black-300 rounded-lg hover:bg-gray-200 hover:border-gray-400">
              <div className="flex flex-row">
                <div className="flex">
                  <div className="px-2 py-1 h-4 justify-items-center">
                    <img
                      src={x.pImages[0]}
                      alt="Product Image"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
                {/* <div className="flex"> */}
                <div className="flex-col px-10">
                  <div className="text-xsl text-black-1000 font-bold">
                    {x.pName}
                  </div>
                  <div className="text-sm text-gray-600">Rs. {x.pPrice}</div>
                </div>
              </div>
            </li>
          </Link>
        );
      };

      const filtered = productArray.filter(
        (product) => product.pName.toLowerCase().includes(search.toLowerCase()) // ||
        // product.pCategory.scName.toLowerCase().includes(search.toLowerCase())
      );

      return (
        <ul>
          {filtered.map((x) => {
            return <ListItem key={x._id} x={x} />;
          })}
        </ul>
      );
    } else {
      return null;
    }
  };

  const searchHandle = (e) => {
    setSearch(e.target.value);
    fetchData(); // can be removed ?
    dispatch({
      type: "searchHandleInReducer",
      payload: e.target.value,
      productArray: productArray,
    });
  };

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      setTimeout(async () => {
        let responseData = await getAllProduct();
        if (responseData && responseData.Products) {
          setPa(responseData.Products);
          dispatch({ type: "loading", payload: false });
        }
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  const closeSearchBar = () => {
    dispatch({
      type: "searchDropdown",
      payload: !homeContextData.searchDropdown,
    });
    fetchData(); // ?? why ?
    dispatch({ type: "setProducts", payload: productArray });
    setSearch("");
    window.location.reload(true);
  };

  return (
    <div className={`${homeContextData.searchDropdown ? "" : "hidden"}`}>
      <div
        style={{
          height: "45px",
          borderRadius: "30px",
          border: "1px solid #7bb5cd",
        }}
        className={`${
          homeContextData.searchDropdown ? "" : "hidden"
          // } my-4 flex items-center justify-between`}
        } my-4 flex items-center justify-between rounded-lg`}
        // } my-4 mx-10 flex items-center justify-center border-yellow-500 border-solid border-4 rounded-lg w-44`}
      >
        <input
          value={search}
          style={{ marginLeft: "10px", height: "20px" }}
          onChange={(e) => searchHandle(e)}
          // className="px-4 text-xl py-4 focus:outline-none"
          className="px-4 text-xl py-4 focus:outline-none flex-1"
          type="text"
          placeholder="Search products..."
        />
        <div onClick={(e) => closeSearchBar()} className="cursor-pointer">
          <svg
            className="w-8 h-8 text-gray-700 hover:bg-gray-200 rounded-full p-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div>
        <SearchResults />
      </div>
    </div>
  );
};
