import React, { Fragment, createContext, useReducer } from "react";
import Layout from "../layout";
import Slider from "./Slider";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
import SingleProduct from "./SingleProduct";
import HomeDesgin from "./HomeDesgin";
import BestSeller from "./BestSeller";
import Review from "./reviews";
export const HomeContext = createContext();

const HomeComponent = () => {
  // window.scrollTo(0, 0);

  return (
    <Fragment>
      <Slider />
      {/* Category, Search & Filter Section */}
      <section className="m-4 md:mx-8 md:my-6">
        <ProductCategory />
      </section>

      {/* Product Section */}
      <section className="m-4 md:mx-8 md:my-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {/* <SingleProduct /> */}
      </section>

      {/* home design section */}
      <section>
        <HomeDesgin />
      </section>

      {/* Best seller section */}
      <section>{/* <BestSeller/> */}</section>
      {/* review */}
      <section>
        <Review />
      </section>
      {/* image */}
      <img style={{ width: "100%" }} src={require("../himg.png")} alt="bgImg" />
    </Fragment>
  );
};

const Home = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </HomeContext.Provider>
    </Fragment>
  );
};

export default Home;
