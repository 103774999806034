import React from "react";
import "./HomeDesign.scss";

function HomeDesgin() {
  return (
    <React.Fragment>
      <div className="MainContainer">
        <div className="autoClass">
          <h4
            style={{
              paddingTop: "100px",
              fontFamily: "Josefin Sans,sans-serif",
            }}
          >
            Tropihill
          </h4>
          <p style={{ paddingTop: "80px" }}>
            Try our coffee directly from our estate!!{" "}
          </p>
          <p>
            Our moto is to provide coffee products at direct factory
            price/wholesale price by skipping all the intermediates.We also provide white labeling in your own brand.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
export default HomeDesgin;
