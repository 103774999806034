import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllSubCategory = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/subcategory/all-subcategory`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSubCategory = async ({
  categoryId,
  scName,
  scImage,
  scDescription,
  scStatus,
}) => {
  let formData = new FormData();
  formData.append("scImage", scImage);
  formData.append("categoryId", categoryId);
  formData.append("scName", scName);
  formData.append("scDescription", scDescription);
  formData.append("scStatus", scStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/subcategory/add-subcategory`,
      formData,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editSubCategory = async (fData, scImage) => {
  //let data = { scId: scId, cDescription: des, cStatus: status };

  let formData = new FormData();
  formData.append("scId", fData.scId);
  if(scImage) formData.append("scImage", scImage);
  else formData.append("scImage", fData.scImage);
  formData.append("categoryId", fData.categoryId);
  formData.append("scName", fData.scName);
  formData.append("scDescription", fData.scDescription);
  formData.append("scStatus", fData.scStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/subcategory/edit-subcategory`,
      formData,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSubCategory = async (scId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/subcategory/delete-subcategory`,
      { scId },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const subCategoriesByCategory = async (catId) => {
  try {
    let res = await axios.post(`${apiURL}/api/subcategory/subcategory-by-category`, {
      catId,
    });
    
    return res.data;
  } catch (error) {
    console.log("Hi: "+error);
  }
};
