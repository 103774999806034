import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { editProduct, getAllProduct } from "./FetchApi";
import { getAllCategory } from "../categories/FetchApi";
import { getAllSubCategory } from "../subCategories/FetchApi";
import { Link } from "react-router-dom";

import Multiselect from "multiselect-react-dropdown";
import RichTextEditor from "react-rte";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import "./style.css";

const apiURL = process.env.REACT_APP_API_URL;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const EditProductModal = (subcategory2) => {
  console.log(subcategory2);
  const [subcategory, setCategories] = useState([]);
  const { data, dispatch } = useContext(ProductContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue());

  function richTextChange(value) {
    const x = value.toString("html");
    setRichText(value);
    setEditformdata({
      ...editformData,
      error: false,
      success: false,
      pDescription: x,
    });
  }

  const [selectedValue, setSelectedValue] = useState({});

  function onSelect(selectedList) {
    const newSubs = selectedList.map((x) => x.id);
    setSelectedValue(selectedList);
    setEditformdata({
      ...editformData,
      error: false,
      success: false,
      pCategory: newSubs,
    });
  }

  function onRemove(selectedList) {
    const newSubs = selectedList.map((x) => x.id);
    setEditformdata({
      ...editformData,
      error: false,
      success: false,
      pCategory: newSubs,
    });
  }

  const [editformData, setEditformdata] = useState({
    pId: "",
    pName: "",
    pBrand: "",
    pStatus: "Active",
    pDescription: "",
    pImages: null,
    pEditImages: null,
    pStatus: "",
    pCategory: "",
    pPrice: 0,
    pTag: [],
    pColor: [
      {
        color: "",
        stock: 0,
      },
    ],
    loading: false,
    pOffer: "",
    error: false,
    success: false,
  });

  useEffect(() => {
    fetchCategoryData();
  }, []);

  async function fetchCategoryData() {
    try {
      let responseData = await getAllSubCategory();
      // console.log({ pCategories: responseData.Categories });

      if (responseData.SubCategories) {
        setCategories(responseData.SubCategories);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const TagData = [
    "arabica coffee",
    "arabica coffee beans",
    "arabica premium",
    "chemical free",
    "coffee roasted",
    "fresh roasted coffee",
    "natural processed",
    "no additives",
    "no artificial",
    "organic",
    "original taste of coffee",
    "premium quality coffee",
    "preservatives no added",
    "robusta coffee",
    "robusta coffee beans",
    "robusta premium",
  ];

  const productTags = data.editProductModal.pTag.map((item) => ({
    value: item,
    label: TagData[item - 1],
  }));

  useEffect(() => {
    var pCategoriesArray = subcategory
      .filter((x) => data.editProductModal.pCategory.includes(x._id))
      .map((y) => ({ id: y._id, name: y.scName }));
    setSelectedValue(pCategoriesArray);
    setEditformdata({
      pId: data.editProductModal.pId,
      pName: data.editProductModal.pName,
      pBrand: data.editProductModal.pBrand,
      pDescription: data.editProductModal.pDescription,
      pImages: data.editProductModal.pImages,
      pStatus: data.editProductModal.pStatus,
      pCategory: data.editProductModal.pCategory,
      pPrice: data.editProductModal.pPrice,
      pColor: data.editProductModal.pColor,
      pOffer: data.editProductModal.pOffer,
      pTag: productTags,
    });
    setRichText(
      RichTextEditor.createValueFromString(
        data.editProductModal.pDescription,
        "html"
      )
    );
    // setSelected(data.editProductModal.pCategoriesArray???);
  }, [data.editProductModal.modal]);

  // for updating the product table
  async function fetchData() {
    let responseData = await getAllProduct();
    if (responseData && responseData.Products) {
      dispatch({
        type: "fetchProductsAndChangeState",
        payload: responseData.Products,
      });
    }
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (!editformData.pEditImages) {
      console.log("Image Not upload=============", editformData);
    } else {
      console.log("Image uploading");
    }
    try {
      setEditformdata({ ...editformData, loading: true });
      let responseData = await editProduct(editformData);
      setEditformdata({ ...editformData, loading: false });
      if (responseData.success) {
        fetchData();
        setEditformdata({ ...editformData, success: responseData.success });
        setTimeout(() => {
          return setEditformdata({
            ...editformData,
            success: responseData.success,
          });
        }, 2000);
      } else if (responseData.error) {
        setEditformdata({ ...editformData, error: responseData.error });
        setTimeout(() => {
          return setEditformdata({
            ...editformData,
            error: responseData.error,
          });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(editformData);
  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) =>
          dispatch({ type: "editProductModalClose", payload: false })
        }
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        style={{
          top: "1rem",
          height: "46rem",
          boxSizing: "border-box",
          overflow: "scroll",
          width: "50%",
          margin: "auto auto",
        }}
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed inset-0 flex items-center z-30 justify-center`}
      >
        <div
          style={{ width: "100%", height: "100%", overflowX: "scroll" }}
          className="mt-32 md:mt-0 relative bg-white md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8"
        >
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Edit Product
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "editProductModalClose", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {editformData.error ? alert(editformData.error, "red") : ""}
          {editformData.success ? alert(editformData.success, "green") : ""}
          {!editformData.loading ? (
            <form className="w-full" onSubmit={(e) => submitForm(e)}>
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                  <label htmlFor="name">Product Name *</label>
                  <input
                    value={editformData.pName}
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pName: e.target.value,
                      })
                    }
                    className="px-4 py-2 border focus:outline-none"
                    type="text"
                  />
                </div>
              </div>
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                  <label htmlFor="name">Brand Name *</label>
                  <input
                    value={editformData.pBrand}
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pBrand: e.target.value,
                      })
                    }
                    className="px-4 py-2 border focus:outline-none"
                    type="text"
                  />
                </div>
              </div>
              {editformData?.pColor?.map((item, i) => (
                <div key={i} className="flex space-x-1 pb-4">
                  <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                    <label htmlFor="price">Product Weight *</label>
                    <input
                      value={editformData.pColor[i].color || ""}
                      onChange={(e) => {
                        const pColor = editformData?.pColor;
                        pColor[i].color = e.target.value;
                        setEditformdata({
                          ...editformData,
                          error: false,
                          success: false,
                          pColor,
                        });
                      }}
                      type="text"
                      className="px-4 py-2 border focus:outline-none"
                      id="price"
                    />
                  </div>
                  <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                    <label htmlFor="price">Product in Stock *</label>
                    <input
                      value={editformData.pColor[i].stock || ""}
                      onChange={(e) => {
                        const pColor = editformData?.pColor;
                        pColor[i].stock = e.target.value;
                        setEditformdata({
                          ...editformData,
                          error: false,
                          success: false,
                          pColor,
                        });
                      }}
                      type="number"
                      className="px-4 py-2 border focus:outline-none"
                      id="stock"
                    />
                  </div>
                </div>
              ))}
              {/* <div className="flex space-x-1 pb-4 justify-end">
                <div>
                  <Link
                    to="#"
                    onClick={(event) => {
                      event.preventDefault();
                      const pColor = editformData.pColor;
                      pColor.push({});
                      setEditformdata({ ...editformData, pColor });
                    }}
                  >
                    <i className="fa fa-lg fa-plus-circle"></i>
                  </Link>
                  &nbsp;
                  <Link
                    to="#"
                    className={
                      editformData?.pColor?.length ?? 0 <= 1
                        ? "text-gray-100"
                        : ""
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      const pColor = editformData?.pColor ?? [];
                      if (pColor.length === 1) {
                        return;
                      }
                      pColor.pop();
                      setEditformdata({ ...editformData, pColor });
                    }}
                  >
                    <i className="fa fa-lg fa-minus-circle"></i>
                  </Link>
                </div>
              </div> */}
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                  <label htmlFor="name">Product Name *</label>
                  <input
                    value={editformData.pPrice}
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pPrice: e.target.value,
                      })
                    }
                    className="px-4 py-2 border focus:outline-none"
                    type="text"
                  />
                </div>
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Tag</label>
                  <ReactSelect
                    options={[
                      { value: 0, label: "None" },
                      ...TagData.map((t, i) => {
                        return { value: i + 1, label: t };
                      }),
                    ]}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={(selected) => {
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pTag: selected,
                      });
                    }}
                    allowSelectAll={true}
                    value={editformData.pTag}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="description">Product Description *</label>
                <RichTextEditor
                  value={richText}
                  onChange={richTextChange}
                  toolbarConfig={toolbarConfig}
                />
              </div>
              {/* Most Important part for uploading multiple image */}
              <div className="flex flex-col mt-4">
                <label htmlFor="image">Product Images *</label>
                {editformData.pImages ? (
                  <div className="flex space-x-1">
                    <img
                      className="h-16 w-16 object-cover"
                      src={editformData.pImages[0]}
                      alt="productImage"
                    />
                    <img
                      className="h-16 w-16 object-cover"
                      src={editformData.pImages[1]}
                      alt="productImage"
                    />
                  </div>
                ) : (
                  ""
                )}
                <span className="text-gray-600 text-xs">
                  Must need 2 images
                </span>
                <input
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pEditImages: [...e.target.files],
                    })
                  }
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="px-4 py-2 border focus:outline-none"
                  id="image"
                  multiple
                />
              </div>
              {/* Most Important part for uploading multiple image */}
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Status *</label>
                  <select
                    value={editformData.pStatus}
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pStatus: e.target.value,
                      })
                    }
                    name="status"
                    className="px-4 py-2 border focus:outline-none"
                    id="status"
                  >
                    <option name="status" value="Active">
                      Active
                    </option>
                    <option name="status" value="Disabled">
                      Disabled
                    </option>
                  </select>
                </div>
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Category *</label>
                  <Multiselect
                    // options={
                    //   categories && categories.length > 0
                    //     ? categories.map((x) => ({
                    //       name: x.cName,
                    //       id: x._id,
                    //     }))
                    //     : []
                    options={
                      subcategory.length > 0
                        ? subcategory.map((x) => ({
                            name: x.scName,
                            id: x._id,
                          }))
                        : []
                    } // Options to display in the dropdown
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    showCheckbox={true}
                  />
                  {/* <select
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        // pCategory: e.target.value,
                      })
                    }
                    name="status"
                    className="px-4 py-2 border focus:outline-none"
                    id="status"
                  >
                    <option disabled value="">
                      Select a category
                    </option>
                    {categories && categories.length > 0
                      ? categories.map((elem) => (
                          <>
                            {editformData.pCategory._id &&
                            editformData.pCategory._id === elem?._id ? (
                              <option
                                name="status"
                                value={elem?._id}
                                key={elem?._id}
                                selected
                              >
                                {elem?.cName}
                              </option>
                            ) : (
                              <option
                                name="status"
                                value={elem?._id}
                                key={elem?._id}
                              >
                                {elem?.cName}
                              </option>
                            )}
                          </>
                        ))
                      : ""}
                  </select> */}
                </div>
              </div>
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="offer">Product Offfer (%) *</label>
                  <input
                    value={editformData.pOffer}
                    onChange={(e) =>
                      setEditformdata({
                        ...editformData,
                        error: false,
                        success: false,
                        pOffer: e.target.value,
                      })
                    }
                    type="number"
                    className="px-4 py-2 border focus:outline-none"
                    id="offer"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
                <button
                  style={{ background: "#303031" }}
                  type="submit"
                  className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                >
                  Update product
                </button>
              </div>
            </form>
          ) : (
            <div className="flex items-center justify-center p-8">
              <svg
                class="w-12 h-12 animate-spin text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditProductModal;
