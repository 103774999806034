import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";

import { getSingleProduct, enquireNow } from "./FetchApi";
import { cartListProduct } from "../partials/FetchApi";

import { isWishReq, unWishReq, isWish } from "../home/Mixins";
import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
import { totalCost } from "../partials/Mixins";

const apiURL = process.env.REACT_APP_API_URL;

const ProductDetailsSection = (props) => {
  let { id } = useParams();

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext); // Layout Context

  const sProduct = layoutData.singleProductDetail;
  const [pImages, setPimages] = useState(null);
  const [count, setCount] = useState(0); // Slide change state
  const [show, setShow] = useState(false);

  const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
  const [alertQ, setAlertq] = useState(false); // Alert when quantity greater than stock
  const [variant, setVarient] = useState(0);
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  ); // Wishlist State Control

  const [fData, setFdata] = useState({
    name: "",
    mobile: "",
    email: "",
    place: "",
    productInterest: "",
    quantity: "",
    message: "",
    success: false,
    error: false,
    loading: false,
  });

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getSingleProduct(id);
      setTimeout(() => {
        if (responseData.Product) {
          layoutDispatch({
            type: "singleProductDetail",
            payload: responseData.Product,
          }); // Dispatch in layout context
          setPimages(responseData.Product.pImages);
          dispatch({ type: "loading", payload: false });
          layoutDispatch({ type: "inCart", payload: cartList() }); // This function change cart in cart state
        }
        if (responseData.error) {
          console.log(responseData.error);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
    fetchCartProduct(); // Updating cart total
  });

  const TagData = [
    "",
    "arabica coffee",
    "arabica coffee beans",
    "arabica premium",
    "chemical free",
    "coffee roasted",
    "fresh roasted coffee",
    "natural processed",
    "no additives",
    "no artificial",
    "organic",
    "original taste of coffee",
    "premium quality coffee",
    "preservatives no added",
    "robusta coffee",
    "robusta coffee beans",
    "robusta premium",
  ];
  const AltData = [
    "",
    "arabicacoffee",
    "arabicacoffeebeans",
    "arabicapremium",
    "chemicalfree",
    "coffeeroasted",
    "freshroastedcoffee",
    "naturalprocessed",
    "noadditives",
    "noartificial",
    "organic",
    "oroginaltasteofcoffee",
    "premiumqualitycoffee",
    "preservativesnoadded",
    "robustacoffee",
    "robustacoffeebeans",
    "robustapremium",
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitEnquireForm = async (e, product) => {
    e.preventDefault();
    e.target.reset();
    debugger;
    if (
      !fData.name ||
      !fData.email ||
      !fData.mobile ||
      !fData.quantity ||
      !fData.message ||
      !fData.productInterest ||
      !fData.place
    ) {
      setFdata({ ...fData, error: "All fields are required." });
      setTimeout(() => {
        setFdata({ ...fData, error: false });
      }, 2000);
      return;
    }
    try {
      dispatch({ type: "loading", payload: true });
      setFdata({ ...fData, loading: true });
      let obj = {
        ...fData,
        pName: product.pName,
        productId: product._id,
      };
      let responseData = await enquireNow(obj);
      dispatch({ type: "loading", payload: false });
      // setFdata({ ...fData, loading: false });
      if (responseData.success) {
        dispatch({ type: "loading", payload: false });
        setFdata({
          name: "",
          mobile: "",
          email: "",
          place: "",
          productInterest: "",
          quantity: "",
          message: "",
          success: responseData.success,
          error: false,
        });
        setTimeout(() => {
          setFdata({
            name: "",
            mobile: "",
            email: "",
            place: "",
            productInterest: "",
            quantity: "",
            message: "",
            success: responseData.success,
            error: false,
            success: false,
          });
        }, 3000);
      } else if (responseData.error) {
        dispatch({ type: "loading", payload: false });
        setFdata({ ...fData, success: false, error: responseData.error });
        setTimeout(() => {
          return setFdata({ ...fData, error: false });
        }, 2000);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      console.log(error);
    }
  };

  const fetchCartProduct = async () => {
    try {
      let responseData = await cartListProduct();
      if (responseData && responseData.Products) {
        layoutDispatch({ type: "cartProduct", payload: responseData.Products }); // Layout context Cartproduct fetch and dispatch
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log({ desc: sProduct?.pDescription });
  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  } else if (!sProduct) {
    return <div>No product</div>;
  }
  return (
    <Fragment>
      <Submenu
        value={{
          // categoryId: sProduct.pCategory._id,
          product: sProduct.pName,
          // category: sProduct.pCategory.cName,
        }}
      />
      <section className="m-4 md:mx-12 md:my-6">
        <div className="grid grid-cols-2 md:grid-cols-12">
          <div className="hidden md:block md:col-span-1 md:flex md:flex-col md:space-y-4 md:mr-2">
            <img
              onClick={(e) =>
                slideImage("increase", 0, count, setCount, pImages)
              }
              className={`${
                count === 0 ? "" : "opacity-25"
              } cursor-pointer w-20 h-20 object-cover object-center`}
              src={sProduct.pImages[0]}
              alt="pic"
            />
            <img
              onClick={(e) =>
                slideImage("increase", 1, count, setCount, pImages)
              }
              className={`${
                count === 1 ? "" : "opacity-25"
              } cursor-pointer w-20 h-20 object-cover object-center`}
              src={sProduct.pImages[1]}
              alt="pic"
            />
          </div>
          <div className="col-span-2 md:col-span-6">
            <div className="relative">
              <img className="w-half" src={sProduct.pImages[count]} alt="Pic" />
              <div className="absolute inset-0 flex justify-between items-center mb-4">
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-span-2 mt-8 md:mt-0 md:col-span-5 md:ml-6 lg:ml-12">
            <div
              style={{
                fontFamily: "Josefin Sans,sans-serif",
              }}
              className="flex flex-col leading-8"
            >
              <div className="text-2xl tracking-wider">{sProduct.pName}</div>
              <div className="flex justify-between items-center">
                {parseInt(sProduct.pOffer) <= 0 ? (
                  <span
                    style={{ fontWeight: "bold", color: "#e4594d" }}
                    className="text-xl tracking-wider text-red-700"
                  >
                    Rs. {sProduct.pPrice}.00
                  </span>
                ) : (
                  <span className="text-xl tracking-wider text-yellow-700">
                    <strike> ₹{sProduct.pPrice}</strike> ₹
                    {sProduct.pPrice -
                      (sProduct.pPrice * parseInt(sProduct.pOffer)) / 100}
                    .00
                  </span>
                )}
                <span>
                  <svg
                    onClick={(e) => isWishReq(e, sProduct._id, setWlist)}
                    className={`${
                      isWish(sProduct._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  <svg
                    onClick={(e) => unWishReq(e, sProduct._id, setWlist)}
                    className={`${
                      !isWish(sProduct._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <br />
              <p
                style={{
                  fontFamily: "Josefin Sans,sans-serif",
                }}
                className="text-xl tracking-wider"
              >
                {sProduct.pBrand}
              </p>
            </div>
            <h5
              style={{
                fontFamily: "Josefin Sans,sans-serif",
              }}
              className="flex flex-col mt-2 w-full"
            >
              <h5
                onChange={(e) => setVarient(e.target.value)}
                className=" py-2"
                id="status"
              >
                {sProduct?.pColor?.map((item, i) => (
                  <option style={{ color: "#b7791f" }} value={i}>
                    {item.color}
                  </option>
                ))}
              </h5>
            </h5>
            <div className="my-4 md:my-4x text-gray-600">
              {/* {sProduct.pDescription} */}
              <div
                style={{
                  fontFamily: "Josefin Sans,sans-serif",
                }}
                className="product-description"
                dangerouslySetInnerHTML={{
                  __html: `${sProduct.pDescription}`,
                }}
              />
              {/* {console.log(sProduct.pTag)} */}
              {sProduct.pTag.filter((p) => p !== 0).length > 0
                ? sProduct.pTag
                    .filter((p) => p !== 0)
                    .map((i) => (
                      <div className="inline-block">
                        <img
                          style={{ height: "5rem", padding: "10px" }}
                          className="h-24"
                          src={require(`../../shop/${AltData[i]}.jpg`)}
                          alt={TagData[sProduct.pTag]}
                        />
                      </div>
                    ))
                : ""}
            </div>
            <div className="my-4 md:my-6">
              {quantitiy == sProduct.pColor[variant].stock ? (
                <span className="text-xs text-red-500">Stock limited</span>
              ) : (
                ""
              )}
              <div
                className={`flex justify-between items-center px-4 py-2 border ${
                  quantitiy === sProduct.pColor[variant].stock &&
                  "border-red-500"
                }`}
              >
                <div
                  className={`${
                    quantitiy === sProduct.pColor[variant].stock &&
                    "text-red-500"
                  }`}
                >
                  Quantity
                </div>
                {/* Quantity Button */}
                {sProduct.pColor[variant].stock !== 0 ? (
                  <Fragment>
                    {layoutData.inCart == null ||
                    (layoutData.inCart !== null &&
                      layoutData.inCart.includes(sProduct._id) === false) ? (
                      <div className="flex items-center space-x-2">
                        <span
                          onClick={(e) =>
                            updateQuantity(
                              "decrease",
                              sProduct.pColor[variant].stock,
                              quantitiy,
                              setQuantitiy,
                              setAlertq
                            )
                          }
                        >
                          <svg
                            className="w-5 h-5 fill-current cursor-pointer"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <span className="font-semibold">{quantitiy}</span>
                        <span
                          onClick={(e) =>
                            updateQuantity(
                              "increase",
                              sProduct.pColor[variant].stock,
                              quantitiy,
                              setQuantitiy,
                              setAlertq
                            )
                          }
                        >
                          <svg
                            className="w-5 h-5 fill-current cursor-pointer"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <span>
                          <svg
                            className="w-5 h-5 fill-current cursor-not-allowed"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <span className="font-semibold">{quantitiy}</span>
                        <span>
                          <svg
                            className="w-5 h-5 fill-current cursor-not-allowed"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div className="flex items-center space-x-2">
                    <span>
                      <svg
                        className="w-5 h-5 fill-current cursor-not-allowed"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className="font-semibold">{quantitiy}</span>
                    <span>
                      <svg
                        className="w-5 h-5 fill-current cursor-not-allowed"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                )}
                {/* Quantity Button End */}
              </div>
              {/* Incart and out of stock button */}
              {sProduct.pColor[variant].stock !== 0 &&
              parseInt(sProduct.pColor[variant].stock) > 0 ? (
                <Fragment>
                  {layoutData.inCart !== null &&
                  layoutData.inCart.includes(sProduct._id) === true ? (
                    <div
                      style={{
                        background: "#f4f1e7",

                        color: "#000",
                      }}
                      className={`px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (
                    <div
                      onClick={(e) =>
                        addToCart(
                          sProduct._id,
                          quantitiy,
                          sProduct.pPrice -
                            (sProduct.pPrice * parseInt(sProduct.pOffer)) / 100,
                          sProduct?.pColor?.[variant]?.color,
                          layoutDispatch,
                          setQuantitiy,
                          setVarient,
                          setAlertq,
                          fetchData,
                          totalCost
                        )
                      }
                      style={{
                        background: "#e4594d",
                        fontFamily: "Josefin Sans,sans-serif",

                        color: "#fff",
                      }}
                      className={`px-4 py-2 text-white text-center cursor-pointer uppercase`}
                    >
                      Add to cart
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {layoutData.inCart !== null &&
                  layoutData.inCart.includes(sProduct._id) === true ? (
                    <div
                      style={{
                        background: "#f4f1e7",
                        color: "#000",
                        fontFamily: "Josefin Sans,sans-serif",
                      }}
                      className={`px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (
                    <div
                      style={{
                        background: "red",
                        fontFamily: "Josefin Sans,sans-serif",
                      }}
                      disabled={true}
                      className="px-4 py-2 text-white opacity-50 cursor-not-allowed text-center uppercase"
                    >
                      Out of stock
                    </div>
                  )}
                </Fragment>
              )}
              <div
                style={{
                  marginTop: "10px",
                  background: "#f4f1e7",
                  fontFamily: "Josefin Sans,sans-serif",
                  color: "#000",
                }}
                className={`px-4 py-2 text-white text-center cursor-pointer uppercase opacity-75`}
                onClick={handleShow}
              >
                Enquire Now
              </div>
              {/* Incart and out of stock button End */}
            </div>
          </div>
        </div>
      </section>
      {/* Product Details Section two */}
      <ProductDetailsSectionTwo />
      {/* Modal Start */}
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      <div
        className={`${
          show ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-12/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span
              style={{ fontFamily: "Josefin Sans,sans-serif" }}
              className="text-left font-semibold text-2xl tracking-wider"
            >
              Enquiry Now
            </span>
            <span
              style={{ background: "#303031" }}
              onClick={handleClose}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form
            className="w-full"
            onSubmit={(e) => submitEnquireForm(e, sProduct)}
          >
            <div className="flex space-x-1 pb-4">
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label
                  style={{ fontFamily: "Josefin Sans,sans-serif" }}
                  htmlFor="name"
                >
                  Name *
                </label>
                <input
                  type="text"
                  value={fData.name}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      name: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  id="name"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label
                  style={{ fontFamily: "Josefin Sans,sans-serif" }}
                  htmlFor="mobile"
                >
                  Phone No. *
                </label>
                <input
                  type="number"
                  value={fData.mobile}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      mobile: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  id="mobile"
                />
              </div>
            </div>
            <div className="flex space-x-1 pb-4">
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label
                  style={{ fontFamily: "Josefin Sans,sans-serif" }}
                  htmlFor="email"
                >
                  Email *
                </label>
                <input
                  type="text"
                  value={fData.email}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      email: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  id="email"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label
                  style={{ fontFamily: "Josefin Sans,sans-serif" }}
                  htmlFor="place"
                >
                  Place *
                </label>
                <input
                  type="text"
                  value={fData.place}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      place: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  id="place"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label
                style={{ fontFamily: "Josefin Sans,sans-serif" }}
                htmlFor="prodInt"
              >
                Product interest *
              </label>
              <input
                className="px-4 py-2 border focus:outline-none"
                type="text"
                id="prodInt"
                value={fData.productInterest}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    productInterest: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label
                style={{ fontFamily: "Josefin Sans,sans-serif" }}
                htmlFor="quant"
              >
                Quantity *
              </label>
              <input
                className="px-4 py-2 border focus:outline-none"
                type="number"
                id="quant"
                value={fData.quantity}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    quantity: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label
                style={{ fontFamily: "Josefin Sans,sans-serif" }}
                htmlFor="message"
              >
                Message *
              </label>
              <textarea
                className="px-4 py-2 border focus:outline-none"
                name="message"
                id="message"
                cols={5}
                rows={5}
                value={fData.message}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    message: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{
                  fontFamily: "Josefin Sans,sans-serif",
                  background: "#f4f1e7",
                  color: "black",
                }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-lg font-medium py-2"
              >
                Enquire Now
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Close Modal */}
    </Fragment>
  );
};

export default ProductDetailsSection;
