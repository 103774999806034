import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import SubCategoryMenu from "./SubCategoryMenu";
import AllSubCategories from "./AllSubCategories";
import { subcategoryState, subcategoryReducer } from "./SubCategoryContext";

/* This context manage all of the caregories component's data */
export const SubCategoryContext = createContext();

const SubCategoryComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <SubCategoryMenu />
      <AllSubCategories />
    </div>
  );
};

const SubCategories = (props) => {
  const [data, dispatch] = useReducer(subcategoryReducer, subcategoryState);
  return (
    <Fragment>
      <SubCategoryContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<SubCategoryComponent />} />
      </SubCategoryContext.Provider>
    </Fragment>
  );
};

export default SubCategories;
