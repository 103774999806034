import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [

  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Products',
    path: '/',
  },
  {
    title: 'About us',
    path: '/aboutus',
  },
  {
    title: 'Contact',
    path: '/contact-us',
  },

  
];
