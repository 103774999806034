import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./about.scss";
const Contact = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Navber />
      <div className="aboutContainer">
        <h3 style={{ textAlign: "center", marginTop: "50px" }}>Contact Us</h3>
        <h3>Our Office Address:</h3>
        <p>
          Samrudhi building, hangal road,
          <br /> chowdlu, somwarpet,
          <br /> kodagu-571236
        </p>
        <h3>Contact:</h3>
        <p>
          +91-9449688811 <br />
          +91-8762006666 <br />
          +91-8431426064
        </p>
        <h3>Our Timing</h3>
        <p>
          Mon - Sat : 10:00 AM - 06:00 PM 
          <br /> Sun : Closed 
        </p>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
