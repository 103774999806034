import React from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./BestSeller.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function BestSeller() {
  return (
    <React.Fragment>
      <div className="bestClass">
        <Carousel className="imgClass" responsive={responsive}>
          <div>
            {" "}
            <img src={require("../t1 (1).jpeg")} alt="bestseller" />
          </div>
          <div>
            <img src={require("../t1 (7).jpeg")} alt="bestseller" />
          </div>
          <div>
            <img src={require("../t1 (6).jpeg")} alt="bestseller" />
          </div>
          <div>
            <img src={require("../t1 (4).jpeg")} alt="bestseller" />
          </div>
          <div>
            <img src={require("../t1 (2).jpeg")} alt="bestseller" />
          </div>
        </Carousel>
      </div>
    </React.Fragment>
  );
}

export default BestSeller;
