export const subcategoryState = {
    subcategories: [],
    addSubCategoryModal: false,
    editSubCategoryModal: {
      modal: false,
      scId: null,
      des: "",
      status: "",
      scName: "",
      scImage: "",
      categoryId: {}
    },
    loading: false,
  };
  
  export const subcategoryReducer = (state, action) => {
    switch (action.type) {
      /* Get all category */
      case "fetchSubCategoryAndChangeState":
        return {
          ...state,
          subcategories: action.payload,
        };
      /* Create a category */
      case "addSubCategoryModal":
        return {
          ...state,
          addSubCategoryModal: action.payload,
        };
      /* Edit a category */
      case "editSubCategoryModalOpen":
        
        return {
          ...state,
          editSubCategoryModal: {
            modal: true,
            scId: action.scId,
            des: action.des,
            status: action.status,
            scName: action.scName,
            scImage: action.scImage,
            categoryId: action.categoryId
          },
        };
      case "editSubCategoryModalClose":
        return {
          ...state,
          editSubCategoryModal: {
            modal: false,
            scId: null,
            des: "",
            status: "",
            scName: "",
            scImage: "",
            categoryId: {}
          },
        };
      case "loading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };
  