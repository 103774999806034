import { getAllEnquiries } from "./FetchApi";

export const fetchData = async (dispatch) => {
  dispatch({ type: "loading", payload: true });
  let responseData = await getAllEnquiries();
  setTimeout(() => {
    if (responseData && responseData.enquiries) {
      dispatch({
        type: "fetchEnquiries",
        payload: responseData.enquiries,
      });
      dispatch({ type: "loading", payload: false });
    }
  }, 1000);
};