import React, { Fragment, useContext, useEffect } from "react";
import moment from "moment";

import { EnquiryContext } from "./index";
import { fetchData } from "./Actions";

const apiURL = process.env.REACT_APP_API_URL;

const AllEnquiry = (props) => {
    const { data, dispatch } = useContext(EnquiryContext);
    const { enquiries, loading } = data;

    useEffect(() => {
        fetchData(dispatch);
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <svg
                    className="w-12 h-12 animate-spin text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    ></path>
                </svg>
            </div>
        );
    }
    return (
        <Fragment>
            <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
                <table className="table-auto border w-full my-2">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Name</th>
                            <th className="px-4 py-2 border">Email</th>
                            <th className="px-4 py-2 border">Phone</th>
                            <th className="px-4 py-2 border">Product Name</th>
                            <th className="px-4 py-2 border">Place</th>
                            <th className="px-4 py-2 border">Product Interest</th>
                            <th className="px-4 py-2 border">Quantity</th>
                            <th className="px-4 py-2 border">Message</th>
                            <th className="px-4 py-2 border">Created at</th>
                            <th className="px-4 py-2 border">Updated at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiries && enquiries.length > 0 ? (
                            enquiries.map((item, i) => {
                                return (
                                    <EnquiryTable
                                        key={i}
                                        enquiry={item}
                                    />
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="12" className="text-xl text-center font-semibold py-8">
                                    No enquiry found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="text-sm text-gray-600 mt-2">
                    Total {enquiries && enquiries.length} enquiry found
                </div>
            </div>
        </Fragment>
    );
};

/* Single Category Component */
const EnquiryTable = ({ enquiry }) => {

    return (
        <Fragment>
            <tr className="border-b">
                <td className="hover:bg-gray-200 p-2 text-center">
                    {enquiry.name}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {enquiry.email}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {enquiry.mobile}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {enquiry.pName}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">{enquiry.place}</td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {enquiry.productInterest}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">{enquiry.quantity}</td>
                <td className="hover:bg-gray-200 p-2 text-center">{enquiry.message}</td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {moment(enquiry.createdAt).format("lll")}
                </td>
                <td className="hover:bg-gray-200 p-2 text-center">
                    {moment(enquiry.updatedAt).format("lll")}
                </td>
            </tr>
        </Fragment>
    );
};

export default AllEnquiry;
