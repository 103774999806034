import React, { Fragment, useContext, useState, useEffect } from "react";
import { SubCategoryContext } from "./index";
import { editSubCategory, getAllSubCategory } from "./FetchApi";
import { getAllCategory } from "../categories/FetchApi";
const EditSubCategoryDetail = ({categories}) => {
  const { data, dispatch } = useContext(SubCategoryContext);

  const [des, setDes] = useState("");
  const [status, setStatus] = useState("");
  const [scId, setSCid] = useState("");

  const [scImage, setImage] = useState(null);
  const [fData, setFdata] = useState({
    scId: "",
    scName: "",
    scDescription: "",
    scImage: "",
    categoryId: "",
    scStatus: "Active",
    success: false,
    error: false,
  });

  useEffect(() => {
    
    setFdata({
      scId: data.editSubCategoryModal.scId,
      scName: data.editSubCategoryModal.scName,
      scDescription: data.editSubCategoryModal.des,
      scImage: data.editSubCategoryModal.scImage,
      scStatus: data.editSubCategoryModal.status,
      categoryId: data.editSubCategoryModal.categoryId,
      success: false,
      error: false,
    })
    // setDes(data.editSubCategoryModal.des);
    // setStatus(data.editSubCategoryModal.status);
    // setSCid(data.editSubCategoryModal.scId);
  }, [data.editSubCategoryModal.modal]);

  const fetchData = async () => {
    let responseData = await getAllSubCategory();
    if (responseData.SubCategories) {
      dispatch({
        type: "fetchSubCategoryAndChangeState",
        payload: responseData.SubCategories,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    e.target.reset();
    dispatch({ type: "loading", payload: true });
    let edit = await editSubCategory(fData, scImage);
    if (edit.error) {
      console.log(edit.error);
      dispatch({ type: "loading", payload: false });
    } else if (edit.success) {
      console.log(edit.success);
      dispatch({ type: "editSubCategoryModalClose" });
      setTimeout(() => {
        fetchData();
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "editSubCategoryModalClose" })}
        className={`${
           data.editSubCategoryModal.modal ? "" : "hidden"
          //data.editSubCategoryModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
           data.editSubCategoryModal.modal ? "" : "hidden"
          //data.editSubCategoryModal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Sub-Category
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) => dispatch({ type: "editSubCategoryModalClose" })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Category *</label>
                <select
                  value={fData.categoryId}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      categoryId: e.target.value,
                    })
                  }
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option disabled value="">
                    Select a category
                  </option>
                  {categories.length > 0
                    ? categories.map(function (elem) {
                      return (
                        <option name="status" value={elem._id} key={elem._id}>
                          {elem.cName}
                        </option>
                      );
                    })
                    : ""}
                </select>
            </div>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label htmlFor="name">Sub-Category Name</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    scName: e.target.value,
                  })
                }
                value={fData.scName}
                className="px-4 py-2 border focus:outline-none"
                type="text"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="description">Sub-Category Description</label>
              <textarea
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    scDescription: e.target.value,
                  })
                }
                value={fData.scDescription}
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={5}
              />
            </div>
            {/* Image Field & function */}
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="name">Sub-Category Image</label>
              <img src={fData.scImage} alt="bestseller" width="100" />
              <input
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
                className="px-4 py-2 border focus:outline-none"
                type="file"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="status">Sub-Category Status</label>
              <select
                name="status"
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    scStatus: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                id="status"
              >
                <option name="status" value="Active">
                  Active
                </option>
                <option name="status" value="Disabled">
                  Disabled
                </option>
              </select>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-lg font-medium py-2"
              >
                Update sub-category
              </button>
            </div>
          </form>
          {/* <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="description">Category Description</label>
            <textarea
              value={des}
              onChange={(e) => setDes(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              name="description"
              id="description"
              cols={5}
              rows={5}
            />
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="status">Category Status</label>
            <select
              value={status}
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              id="status"
            >
              <option name="status" value="Active">
                Active
              </option>
              <option name="status" value="Disabled">
                Disabled
              </option>
            </select>
          </div>
          <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
            <button
              style={{ background: "#303031" }}
              onClick={(e) => submitForm()}
              className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
            >
              Create category
            </button>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};


const EditSubCategoryModal = (props) => {
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const [allCat2, setAllCat2] = useState({});

  const fetchCategoryData = async () => {
    let responseData = await getAllCategory();
    
    if (responseData.Categories) {
      setAllCat2(responseData.Categories);
      
      if(allCat2.length){
        debugger
      }
    }
  };

  return (
    <Fragment>
      <EditSubCategoryDetail categories={allCat2} />
    </Fragment>
  );
};
export default EditSubCategoryModal;
