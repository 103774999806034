import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const getAllEnquiries = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/enquiry/list`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
