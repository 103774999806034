import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./terms.scss";

const Shipping = () => {
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Navber />
      <div className="termsContainer">
        <h3 style={{ marginTop: "30px", textAlign: "center" }}>
          Shipping & Payment Policy
        </h3>
        <h3>SHIPPING</h3>

        <p>
          All orders are dispatched within 48 hours of placing the order. The
          only exception to this is if the next 48 hours falls on a Sunday or a
          public holiday.
        </p>
        <p>
          We will notify you along with the tracking details as soon as we have
          shipped the goods.
        </p>
        <h3>DELIVERY</h3>
        <h3>Domestic Delivery</h3>
        <p>
          Under normal circumstances all orders are delivered within 4-5
          business days of the order being shipped. However, remote locations
          may take 5-7 business days.
          <br />
          <br />
          Note: For any other delivery related issues, customers shall reach us
          at tropihill@gmail.com or WhatsApp 8762006666 along with the
          full delivery address, order id and the issue faced with delivery.
        </p>
        <h3>International Delivery</h3>
        <p>
          We ship our international orders DHL Ecommerce/ India Post for
          standard shipping. Please expect a delivery timeline of 2-3 weeks for
          the shipment to arrive to your place. In case you choose express
          shipping, we will ship the goods via DHL/ FedEx/ UPS depending upon
          the best courier company for your country.
          <br />
          <br />
          Some countries levy an import duty or taxes on goods which will be
          borne by the customer. Please check your local laws for more details.
          <br />
          <br />
          Feel free to reach out to us at tropihill@gmail.com if you face any
          issues with deliveries.
        </p>
        <h3>Payment Method:</h3>
        <li>
          Domestic Orders: Domestic customer can use our website service to
          place an order and make a payment through Debit Card, Credit Card, Net
          Banking or UPI payment.
        </li>

        <li>
          International Orders: Kindly reach out to us at tropihill@gmail.com or
          WhatsApp 8762006666 for international order.
        </li>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Shipping;
