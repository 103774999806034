import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { createProduct, getAllProduct } from "./FetchApi";
import { getAllSubCategory } from "../subCategories/FetchApi";
import Multiselect from "multiselect-react-dropdown";
import RichTextEditor from "react-rte";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const AddProductDetail = ({ subcategory }) => {
  console.log(subcategory);

  const { data, dispatch } = useContext(ProductContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue());

  // const options = [{name: 'Option 1', id: 1},{name: 'Option 2', id: 2}];

  const [selectedValue, setSelectedValue] = useState({});

  function onSelect(selectedList) {
    
    // console.log('selectedList', selectedList, 'selectedItem', selectedItem);
    const newSubs = selectedList.map((x) => x.id?x.id:x);
    setSelectedValue(newSubs);
    setFdata({
      ...fData,
      error: false,
      success: false,
      pCategory: newSubs,
    });
  }

  function onRemove(selectedList, removedItem) {
    // console.log('selectedList', selectedList, 'removedItem', removedItem);
    const newSubs = selectedList.map((x) => x.id);
    // console.log('newSubs', newSubs);
    setFdata({
      ...fData,
      error: false,
      success: false,
      pCategory: newSubs,
    });
  }

  const [fData, setFdata] = useState({
    pName: "",
    pBrand: "",
    pDescription: "",
    pStatus: "Active",
    pTag: { value: 0, label: "None" },
    pImage: null, // Initial value will be null or empty array
    pCategory: "",
    pPrice: "",
    color: [
      {
        color: 0,
        stock: 0,
      },
    ],
    pOffer: 0,
    pQuantity: "",
    success: false,
    error: false,
    loading: false,
  });

  function richTextChange(value) {
    const x = value.toString("html");
    setRichText(value);
    setFdata({
      ...fData,
      error: false,
      success: false,
      pDescription: x,
    });
  }

  const TagData = [
    "arabica coffee",
    "arabica coffee beans",
    "arabica premium",
    "chemical free",
    "coffee roasted",
    "fresh roasted coffee",
    "natural processed",
    "no additives",
    "no artificial",
    "organic",
    "original taste of coffee",
    "premium quality coffee",
    "preservatives no added",
    "robusta coffee",
    "robusta coffee beans",
    "robusta premium",
  ];

  const fetchData = async () => {
    let responseData = await getAllProduct();
    if (responseData && responseData.Products) {
      dispatch({
        type: "fetchProductsAndChangeState",
        payload: responseData.Products,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    e.target.reset();
    debugger
    if (!fData.pImage) {
      setFdata({ ...fData, error: "Please upload at least 2 image" });
      setTimeout(() => {
        setFdata({ ...fData, error: false });
      }, 2000);
    }
    try {
      setFdata({ ...fData, loading: true });
      let responseData = await createProduct(fData);
      setFdata({ ...fData, loading: false });
      if (responseData.success) {
        fetchData();
        setFdata({
          ...fData,
          pName: "",
          pBrand: "",
          pDescription: "",
          pImage: "",
          pStatus: "Active",
          pTag: { value: 0, label: "None" },
          pCategory: "",
          pPrice: "",
          color: [{}],
          pQuantity: "",
          pOffer: 0,
          success: responseData.success,
          error: false,
        });
        setTimeout(() => {
          setFdata({
            ...fData,
            pName: "",
            pBrand: "",
            pDescription: "",
            pImage: "",
            pStatus: "Active",
            pTag: { value: 0, label: "None" },
            pCategory: "",
            pPrice: "",
            color: [{}],
            pQuantity: "",
            pOffer: 0,
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        setTimeout(() => {
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={() => dispatch({ type: "addProductModal", payload: false })}
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        style={{
          top: "1rem",
          height: "47rem",
          boxSizing: "border-box",
          overflow: "scroll",
        }}
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed inset-0 flex items-center z-30 justify-center`}
      >
        <div className="mt-32 md:mt-0 relative bg-white md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span
              style={{ marginTop: "250px" }}
              className="text-left font-semibold text-2xl tracking-wider"
            >
              Add Product
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031", marginTop: "250px" }}
              onClick={(e) =>
                dispatch({ type: "addProductModal", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          {!fData.loading ? (
            <form className="w-full" onSubmit={(e) => submitForm(e)}>
              <div
                className="flex space-x-1 py-4"
                style={{ marginTop: "3rem" }}
              >
                <div className="w-full flex flex-col space-y-1 space-x-1">
                  <label htmlFor="name">Product Name *</label>
                  <input
                    value={fData.pName}
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        pName: e.target.value,
                      })
                    }
                    className="px-4 py-2 border focus:outline-none"
                    type="text"
                  />
                </div>
              </div>

              <div className="w-full flex flex-col space-y-1 space-x-1">
                <label htmlFor="name">Brand Name *</label>
                <input
                  value={fData.pBrand}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pBrand: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>

              {fData?.color?.map((item, i) => (
                <div key={i} className="flex space-x-1 pb-4">
                  <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                    <label htmlFor="color">Additional Information *</label>
                    <input
                      value={fData.color[i].color || ""}
                      onChange={(e) => {
                        const color = fData?.color;
                        color[i].color = e.target.value;
                        setFdata({
                          ...fData,
                          error: false,
                          success: false,
                          color,
                        });
                      }}
                      type="text"
                      className="px-4 py-2 border focus:outline-none"
                      id="color"
                    />
                  </div>
                  <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                    <label htmlFor="stock">Product in stock *</label>
                    <input
                      value={fData.color[i].stock || ""}
                      onChange={(e) => {
                        const color = fData?.color;
                        color[i].stock = e.target.value;
                        setFdata({
                          ...fData,
                          error: false,
                          success: false,
                          color,
                        });
                      }}
                      type="number"
                      className="px-4 py-2 border focus:outline-none"
                      id="stock"
                    />
                  </div>
                </div>
              ))}
              {/* <div className="flex space-x-1 pb-4 justify-end">
              <div>
                <Link to='#' onClick={(event) => { event.preventDefault(); const color = fData.color; color.push({}); setFdata({ ...fData, color }); }}><i className="fa fa-lg fa-plus-circle"></i></Link>
                &nbsp;
                <Link to='#' className={(fData.color.length <= 1) ? 'text-gray-100' : ''} onClick={(event) => { event.preventDefault(); const color = fData.color; if (color.length === 1) { return; } color.pop(); setFdata({ ...fData, color }); }}><i className="fa fa-lg fa-minus-circle"></i></Link>
              </div>
            </div> */}

              {/* <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="price">Product Price *</label>
                <input
                  value={fData.pPrice}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pPrice: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="price"
                />
            </div> */}

              {/* start */}
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="price">Product Price *</label>
                  <input
                    value={fData.pPrice}
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        pPrice: e.target.value,
                      })
                    }
                    type="number"
                    className="px-4 py-2 border focus:outline-none"
                    id="price"
                  />
                </div>
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Tag</label>
                  <ReactSelect
                    options={[
                      { value: 0, label: "None" },
                      ...TagData.map((t, i) => {
                        return { value: i + 1, label: t };
                      }),
                    ]}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={(selected) => {
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        pTag: selected,
                      });
                    }}
                    allowSelectAll={true}
                    value={fData.pTag}
                  />
                </div>
              </div>

              {/* end */}

              <div className="flex flex-col space-y-2">
                <label htmlFor="description">Product Description *</label>
                <RichTextEditor
                  value={richText}
                  onChange={richTextChange}
                  toolbarConfig={toolbarConfig}
                />
                {/* <textarea
                value={fData.pDescription}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    pDescription: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={2}
              /> */}
              </div>
              {/* Most Important part for uploading multiple image */}
              <div className="flex flex-col mt-4">
                <label htmlFor="image">Product Images *</label>
                <span className="text-gray-600 text-xs">
                  Must need 2 images
                </span>
                <input
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pImage: [...e.target.files],
                    })
                  }
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="px-4 py-2 border focus:outline-none"
                  id="image"
                  multiple
                />
              </div>
              {/* Most Important part for uploading multiple image */}
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Status *</label>
                  <select
                    value={fData.pStatus}
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        pStatus: e.target.value,
                      })
                    }
                    name="status"
                    className="px-4 py-2 border focus:outline-none"
                    id="status"
                  >
                    <option name="status" value="Active">
                      Active
                    </option>
                    <option name="status" value="Disabled">
                      Disabled
                    </option>
                  </select>
                </div>
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="status">Product Category *</label>
                  <Multiselect
                    options={
                      subcategory.length > 0
                        ? subcategory.map((x) => ({
                            name: x.scName,
                            id: x._id,
                          }))
                        : []
                    } // Options to display in the dropdown
                    value={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    showCheckbox={true}
                  />
                  {/* <select
                  value={fData.pCategory}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pCategory: e.target.value,
                    })
                  }
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option disabled value="">
                    Select a category
                  </option>
                  {subcategory.length > 0
                    ? subcategory.map(function (elem) {
                      return (
                        <option name="status" value={elem._id} key={elem._id}>
                          {elem.scName}
                        </option>
                      );
                    })
                    : ""}
                </select> */}
                </div>
              </div>
              <div className="flex space-x-1 py-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <label htmlFor="offer">Product Offfer (%) *</label>
                  <input
                    value={fData.pOffer}
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        pOffer: e.target.value,
                      })
                    }
                    type="number"
                    className="px-4 py-2 border focus:outline-none"
                    id="offer"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
                <button
                  style={{ background: "#303031" }}
                  type="submit"
                  className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                >
                  Create product
                </button>
              </div>
            </form>
          ) : (
            <div className="flex items-center justify-center p-8">
              <svg
                class="w-12 h-12 animate-spin text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const AddProductModal = (props) => {
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const [allCat, setAllCat] = useState({});

  const fetchCategoryData = async () => {
    try {
      let responseData = await getAllSubCategory();
      if (responseData?.SubCategories) {
        
        setAllCat(responseData.SubCategories);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <AddProductDetail subcategory={allCat} {...{ ...props }} />
    </Fragment>
  );
};

export default AddProductModal;
