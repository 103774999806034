export const enquiryState = {
    enquiries: [],
    loading: false,
  };
  
  export const enquiryReducer = (state, action) => {
    switch (action.type) {
      /* Get all category */
      case "fetchEnquiries":
        return {
          ...state,
          enquiries: action.payload,
        };
      case "loading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };