import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const getAllProduct = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/product/all-product`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductData = async () => {};

export const createPorductImage = async ({ pImage }) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  /* Most important part for uploading multiple image  */
};

export const createProduct = async ({
  pName,
  pBrand,
  pTag,
  pDescription,
  pImage,
  pStatus,
  pCategory,
  // categoriesArray,
  pPrice,
  color,
  pOffer,
}) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  
  /* Most important part for uploading multiple image  */
  formData.append("pName", pName);
  formData.append("pBrand", pBrand);
  formData.append("pTag", JSON.stringify(pTag.map((s) => s.value)));
  formData.append("pDescription", pDescription);
  formData.append("pStatus", pStatus);
  // formData.append("pCategory", pCategory);
  console.log("categoriesArray", pCategory);
  formData.append("categoriesArray", pCategory);
  formData.append("pPrice", pPrice);
  formData.append("color", JSON.stringify(color));
  formData.append("pOffer", pOffer);
  try {
    let res = await axios.post(`${apiURL}/api/product/add-product`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = async (product) => {
  console.log(product);
  
  /* Most important part for updating multiple image  */
  let formData = new FormData();
  if (product.pEditImages) {
    for (const file of product.pEditImages) {
      formData.append("pEditImages", file);
    }
  }
  
  /* Most important part for updating multiple image  */
  
  let tags = product.pTag.map((x) => x.value);
  formData.append("pId", product.pId);
  formData.append("pName", product.pName);
  formData.append("pBrand", product.pBrand);
  formData.append("categoriesArray", product.pCategory);
  formData.append("pTag", JSON.stringify(tags));
  formData.append("pDescription", product.pDescription);
  formData.append("pStatus", product.pStatus);
  // formData.append("pCategory", product.pCategory._id);
  formData.append("pPrice", product.pPrice);
  formData.append("pColor", JSON.stringify(product.pColor));
  formData.append("pOffer", product.pOffer);
  formData.append("pImages", product.pImages);

  try {
    let res = await axios.post(`${apiURL}/api/product/edit-product`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (pId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/delete-product`, { pId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByCategory = async (catId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-category`, {
      catId,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByPrice = async (price) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-price`, {
      price,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
