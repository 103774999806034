import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./terms.scss";

const Return = () => {
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Navber />
      <div className="termsContainer">
        <h3 style={{ textAlign: "center" }}>Cancellation Policy </h3>
        <h3 style={{ marginTop: "30px" }}> Return Policy: </h3>

        <p>
          We,tropihill follows a discipline quality check before shipping the
          product. We take return of the product if it is in damaged condition.
        </p>

        <h3>Conditions:</h3>

        <li>
          We request you to take unboxing video to process the return request.{" "}
        </li>

        <li>
          We accepts return incase client receives a wrong product or the
          product in damaged condition only.{" "}
        </li>
        <li>
          Kindly inform us within 24hrs of product receives to process the
          return request for damaged/ wrong product.{" "}
        </li>

        <li>
          The damaged product will be replaced with new product. The cost of
          returning the damaged product borne by our side.
        </li>

        <li>
          Once we recieve the goods and we find the reason provide for return
          turn to to false If any issue or clarification you can reach us to our
          whatsapp:8762006666 or call us at 9449688811
        </li>

        <li>
          If any issue or clarification you can reach us to our WhatsApp: +91-8762006666 or E-mail: tropihill@gmail.com
        </li>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Return;
