import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import Enquiry from "./enquiry";
import { enquiryState, enquiryReducer } from "./EnquiryContext";

/* This context manage all of the Enquiries component's data */
export const EnquiryContext = createContext();

const EnquiryComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <Enquiry />
    </div>
  );
};

const Enquiries = (props) => {
  const [data, dispatch] = useReducer(enquiryReducer, enquiryState);
  return (
    <Fragment>
      <EnquiryContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<EnquiryComponent />} />
      </EnquiryContext.Provider>
    </Fragment>
  );
};

export default Enquiries;
