import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./about.scss";
import BestSeller from "../home/BestSeller";
const About = () => {
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Navber />
      <div className="aboutContainer">
        <h3 style={{ marginTop: "50px" }}>About Tropihill </h3>
        <p>
          We are proud to announce that we, Tropihill Pvt. Ltd., situated at
          Somwarpet, Coorg, Karnataka, house an extensive range of coffees for
          commercial and private customers. We have an extensive range of over a
          number of original Coffee directly sourced from our estate"Samrudhi
          estate". Our team is made up of very passionate and dedicated
          professionals in the field of coffee and are committed to
          provide quality and excellence to all. we are into complete end to end
          coffee manufacturing, that is we grow coffee, we preprocess it(curing
          process), we post process it(roasting process) and pack it as an end
          product making us to provide coffee at direct factory price/wholesale
          price.
        </p>
        <BestSeller />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default About;
