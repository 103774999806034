import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./footer.scss";
const Footer = (props) => {
  return (
    <Fragment>
      {/* <footer
        style={{ background: "#303031", color: "#87898A" }}
        className="z-10 py-6 px-4 md:px-12 text-center"
      >
        Develop & Design oddu © Copyright {moment().format("YYYY")}
      </footer> */}
      <div className="footerClass">
        {" "}
        <footer className="footerContainer">
          <div>
            <img src={require("../../shop/logo.jpg")} alt="logo" />
          </div>
          <div className="information">
            <h3>Policies</h3>
            <ul>
              <Link to="/terms-and-condition">
                {" "}
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/privacy-policy">
                {" "}
                <li>Privacy Policy</li>
              </Link>
              <Link to="/return-policy">
                {" "}
                <li>Return Policy</li>
              </Link>
              <Link to="/shipping-payment-policy">
                {" "}
                <li>Shipping & Payment Policy </li>
              </Link>
            </ul>
          </div>
          <div className="information">
            <h3>Quick Links</h3>
            <ul>
              <Link to="/">
                {" "}
                <li>Home</li>
              </Link>
              <Link to="/">
                {" "}
                <li>Products</li>
              </Link>
              <Link to="/aboutus">
                {" "}
                <li>About Us</li>
              </Link>
              <Link to="/contact-us">
                {" "}
                <li>Contact Us</li>
              </Link>
            </ul>
          </div>
          <div className="information">
            <h3>Contact</h3>
            <ul>
              <li>
                Samrudhi building, hangal road,
                <br /> chowdlu, somwarpet,
                <br /> kodagu-571236
              </li>
              <li>Email: tropihill@gmail.com </li>
              <li>Contact: +91-9449688811, +91-8762006666, +91-8431426064</li>
            </ul>
          </div>
        </footer>
        <p style={{ textAlign: "center", color: "#000" }}>
          {" "}
          © Copyright {moment().format("YYYY")},Tropihill.
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
